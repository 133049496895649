/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                var hamby = jQuery('.hamburger');
                var nav = jQuery('.nav-primary');

                hamby.on('click', function() {
                    hamby.toggleClass('is-active');
                    nav.toggleClass('is-active');
                });

                // Select all links with hashes
                $('a[href*="#"]')
                // Remove links that don't actually link to anything
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .click(function(event) {
                        // On-page links
                        if (
                            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                            &&
                            location.hostname == this.hostname
                        ) {
                            // Figure out element to scroll to
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            // Does a scroll target exist?
                            if (target.length) {
                                // Only prevent default if animation is actually gonna happen
                                event.preventDefault();
                                $('html, body').animate({
                                    scrollTop: target.offset().top
                                }, 1000);
                            }
                        }
                    });

                /*$.ajax({
                  type: 'POST',
                  url: ajaxurl,
                  dataType: "json", // add data type
                  data: { action : 'get_ajax_posts' },
                  success: function( response ) {
                    $.each( response, function( key, value ) {
                      console.log( key, value ); // that's the posts data.
                    } );
                  }
                });*/
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                jQuery('.pageslider').slick({
                    arrows: true,
                    fade: true,
                    autoplay: true,
                    pauseOnHover: false,
                    autoplaySpeed: 3000,
                    nextArrow: '<div class="arrow right"></div>',
                    prevArrow: '<div class="arrow left"></div>',
                });

                jQuery('.teamslide').slick({
                    arrows: true,
                    fade: false,
                    autoplay: true,
                    pauseOnHover: false,
                    autoplaySpeed: 3000,
                    nextArrow: '<div class="arrow right"></div>',
                    prevArrow: '<div class="arrow left"></div>',
                });

                jQuery('.toggle-box').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('active')) {
                        $('.toggle-box').removeClass('active');
                        $('.toggle-content').removeClass('active');
                        $(this).removeClass('active');
                        $(this).next('.toggle-content').removeClass('active');
                        history.replaceState(null, null, ' ');
                    } else {
                        $('.toggle-box').removeClass('active');
                        $('.toggle-content').removeClass('active');
                        $(this).addClass('active');
                        $(this).next('.toggle-content').addClass('active');
                        location.hash = $(this).data('job-id');

                    }
                    // $grid.isotope("layout");
                });

                /*$(".toggle-box").click(function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('active')) {
                        $(this).next('.toggle-content').slideUp("fast");
                        $('dt > a').removeClass('open').addClass("closed");
                        $('dt span').removeClass('active');
                    } else {
                        $('dd').slideUp("fast");
                        $('dt > a').removeClass('open').addClass("closed");
                        $(this).next("dd").slideDown("fast");
                        $(this).children("a").removeClass('closed').addClass("open");
                        $(this).find('span').addClass('active');
                    }
                });*/

                // DISPLAY FORM
                jQuery('a[data-job-id]').on('click', function (e) {
                    e.preventDefault();

                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        $(this).find('.recruit-form').remove();
                        $('.recruit-form').addClass('d-none');
                    } else {
                        $('a[data-job-id]').removeClass('active');
                        $(this).addClass('active');
                        $(this).parent().append(jQuery('.recruit-form'));
                        $('.recruit-form').removeClass('d-none');
                    }

                    $grid.isotope("layout");
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_template_suchende': {
            finalize: function () {
                var $grid = $('#jobResults').isotope({
                    layoutMode: 'vertical',
                    vertical: {
                        horizontalAlignment: 0.5,
                    }
                });

                var filters = {};

                // HANDLE OPEN/CLOSE
                jQuery('.toggle-box').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('active')) {
                        $('.toggle-box').removeClass('active');
                        $('.toggle-content').removeClass('active');
                        $(this).removeClass('active');
                        $(this).next('.toggle-content').removeClass('active');
                        history.replaceState(null, null, ' ');
                    } else {
                        $('.toggle-box').removeClass('active');
                        $('.toggle-content').removeClass('active');
                        $(this).addClass('active');
                        $(this).next('.toggle-content').addClass('active');
                        location.hash = $(this).data('job-id');

                    }
                    $grid.isotope("layout");
                });

                // DISPLAY FORM
                jQuery('a[data-job-id]').on('click', function (e) {
                    e.preventDefault();

                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        $(this).find('.recruit-form').remove();
                        $('.recruit-form').addClass('d-none');
                    } else {
                        $('a[data-job-id]').removeClass('active');
                        $(this).addClass('active');
                        $(this).parent().append(jQuery('.recruit-form'));
                        $('.recruit-form').removeClass('d-none');
                        $('#input_3_8').val($(this).attr('data-job-id'));
                    }

                    $grid.isotope("layout");
                });

                $('select').change(function(){
                    var $this = $(this);
                    var group = $this.attr('data-filter-group');

                    filters[ group ] = $this.find(':selected').attr('data-filter-value');
                    var isoFilters = [];
                    for ( var prop in filters ) {
                        isoFilters.push( filters[ prop ] );
                    }
                    var selector = isoFilters.join('');
                    $grid.isotope({ filter: selector });
                    return false;
                });

                $('.expand-filter').on('click', function() {
                    $(this).toggleClass('active');
                   $('.job-filter-panel').toggle();
                });

                $('.job-alarm-btn').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    $(this).parent().append(jQuery('.jobalarm-form'));
                    $('.jobalarm-form').toggleClass('d-none');
                });

            }
        },
       /* 'page_template_template_unternehmer': {
            finalize: function () {

                var firstForm = $($('.contact-form-embed')[0]);
                var lastForm = $($('.contact-form-embed')[1]);
                var meldeplichtForm = $('#gform_wrapper_6');

                $('.header-btn').on('click', function(e) {
                    e.preventDefault();
                    lastForm.toggleClass('d-none');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: lastForm.offset().top
                    }, 1200);
                });

                $('.btn-violet').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    lastForm.toggleClass('d-none');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: lastForm.offset().top
                    }, 1200);
                });

                $('.meldepflicht').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    meldeplichtForm.toggleClass('active');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: meldeplichtForm.offset().top
                    }, 1200);
                });

            }
        },*/
        'page_template_template_unternehmer': {
            finalize: function () {

                var firstForm = $($('.contact-form-embed')[0]);
                var lastForm = $($('.contact-form-embed')[1]);
                var meldeplichtForm = $('#gform_wrapper_6');

                $('.header-btn').on('click', function(e) {
                    e.preventDefault();
                    lastForm.toggleClass('d-none');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: lastForm.offset().top
                    }, 1200);
                });

                $('.btn-violet').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    lastForm.toggleClass('d-none');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: lastForm.offset().top
                    }, 600);
                });

                $('.meldepflicht').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    meldeplichtForm.toggleClass('active');
                    $('.cta').click();
                    /*$([document.documentElement, document.body]).animate({
                        scrollTop: meldeplichtForm.offset().top
                    }, 1200);*/
                });

                var orgCta = $('.gradient-grey .cta').find('img').attr('src');

                $('.gradient-grey .cta').on('click', function(e) {
                    e.preventDefault();
                    if($(this).hasClass('active')) {
                        $(this).find('img').attr('src', orgCta);
                        $(this).removeClass('active');
                    } else {
                        $(this).find('img').attr('src', '/wp-content/uploads/2019/07/Icon_Sprechblasen_Stellenmeldepflicht_2.svg');
                        $(this).addClass('active');
                    }
                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
